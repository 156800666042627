import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle,
  faUser,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const colors = {
  red: "text-red-500",
  orange: "text-orange-500",
  green: "text-green-500",
};

const TodoList = () => {
  const [todoLists, setTodoLists] = useState({});

  useEffect(() => {
    fetch("/todo.json")
      .then((response) => response.json())
      .then((data) => setTodoLists(data))
      .catch((error) => console.error("Error fetching the todo list:", error));
  }, []);

  const isApplicationSection = (listName) => listName.startsWith("Application");

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Todo (Mis à jour le 7 aout)</h1>
      {Object.keys(todoLists).map((listName) => (
        <div key={listName} className="mb-6">
          <h2 className="text-xl font-semibold mb-2 flex items-center">
            {listName}
            {isApplicationSection(listName) && todoLists[listName].github && (
              <a
                href={todoLists[listName].github}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-blue-500"
              >
                GitHub
              </a>
            )}
          </h2>
          {todoLists[listName].tasks && todoLists[listName].tasks.length > 0 ? (
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {todoLists[listName].tasks.map((todo) => (
                <li
                  key={todo.id}
                  className="flex flex-col p-4 bg-white rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon
                      icon={todo.completed ? faCheckCircle : faCircle}
                      className={`mr-2 ${colors[todo.color]}`}
                    />
                    <span
                      className={`${todo.completed ? "line-through" : ""} ${
                        colors[todo.color]
                      }`}
                    >
                      {todo.task}
                    </span>
                  </div>
                  <div className="text-gray-600 mb-2">
                    {todo.who && (
                      <p className="flex items-center">
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        {todo.who}
                      </p>
                    )}
                    {todo.date && (
                      <p className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="mr-2"
                        />
                        {todo.date}
                      </p>
                    )}
                  </div>
                  {todo.details && (
                    <div className="text-gray-500 italic">{todo.details}</div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 italic">Pas de tâche pour l'instant</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default TodoList;
